import React from "react"
import SortableTable from "./sortable-table"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

export default function Resolutions({ resolutions }) {
  const columns = [
    {
      field: "name",
      header: "City",
    },
    {
      field: "date",
      header: "Date Passed",
      render({ date }) {
        return dayjs(date).format("MMMM D, YYYY")
      },
    },
    {
      header: "",
      isSortable: false,
      className: "text-right",
      render({ publicURL }) {
        return (
          <a href={publicURL} target="_blank" rel="noreferrer" title="Download">
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )
      },
    },
  ]

  return (
    <>
      <SortableTable
        className="w-full xl:w-3/4 xl:mx-auto"
        rows={resolutions}
        columns={columns}
        defaultSort="name:asc"
      />
    </>
  )
}
