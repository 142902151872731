import React, { useState, useEffect } from "react"
import sortBy from "lodash.sortby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons"

export default function SortableTable({
  columns,
  rows,
  defaultSort,
  className = "",
  caption,
  beforeTableHeader = function () {},
}) {
  const [sortedRows, setSortedRows] = useState([])
  const [defaultSortedField, defaultSortDirection] = defaultSort.split(":")
  const [currentSortedField, setCurrentSortedField] = useState(
    defaultSortedField
  )
  const [currentSortDirection, setCurrentSortDirection] = useState(
    defaultSortDirection
  )

  useEffect(() => {
    let initialSortedRows = sortBy(rows, [currentSortedField])

    if (currentSortDirection === "desc") {
      initialSortedRows = initialSortedRows.reverse()
    }

    setSortedRows(initialSortedRows)
  }, [rows, currentSortedField, currentSortDirection])

  function sortRows(field) {
    if (field === currentSortedField) {
      if (currentSortDirection === "asc") {
        setCurrentSortDirection("desc")
      } else {
        setCurrentSortDirection("asc")
      }
    } else {
      setCurrentSortedField(field)
      setCurrentSortDirection("asc")
    }
  }

  return (
    <table className={className}>
      {caption && <caption className="text-left">{caption()}</caption>}
      <thead>
        {beforeTableHeader()}
        <tr>
          {columns.map(({ field, header, isSortable = true }, i) => {
            const key = `th-${i}-${field}`
            if (!isSortable) {
              return (
                <th key={key} className="cursor-not-allowed">
                  {header}
                </th>
              )
            }

            return (
              <th
                key={key}
                onClick={() => sortRows(field)}
                className="cursor-pointer"
              >
                <span className="mr-2">{header ? header : field}</span>
                <span>
                  {currentSortedField === field && (
                    <FontAwesomeIcon
                      icon={
                        currentSortDirection === "asc" ? faSortUp : faSortDown
                      }
                    />
                  )}
                </span>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {sortedRows.map((row, i) => {
          return (
            <tr className="border-b" key={`resolution-row-${i}`}>
              {columns.map(({ field, render, className = "" }, i) => {
                return (
                  <td
                    className={`py-3 ${className}`}
                    key={`cell-${field}-${i}`}
                  >
                    {render ? render(row, field) : row[field]}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
