import { graphql, useStaticQuery } from "gatsby"

const useResolutions = () => {
  const data = useStaticQuery(graphql`
    query ResolutionsQuery {
      allFile {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)

  return data.allFile.edges.map(({ node }) => {
    const [city] = node.name.split("-")
    const [, date] = node.name.toLowerCase().split("passed")

    return {
      ...node,
      name: city.trim(),
      date: new Date(date.replace("on", "").replace(/-/g, "/").trim()),
    }
  })
}

export default useResolutions
