import ResolutionsTable from "../components/resolutions-table"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useResolutions from "../hooks/use-resolutions"
import dayjs from "dayjs"

export default function Resolutions() {
  const resolutions = useResolutions()

  return (
    <Layout>
      <SEO title="Resolutions" />
      <h1>Resolutions</h1>
      <div>
        <blockquote className="px-5 text-lg">
          As of {dayjs().format("M/D/YYYY")}, {resolutions.length} California
          Cities have passed resolutions supporting our mission.
          <small className="block text-xs italic">
            (The number of resolutions passed is actually higher, but the count
            only includes resolutions that we have actually received signed
            copies.)
          </small>
        </blockquote>
        <div></div>
      </div>
      <p>
        Many Cities have taken the extra effort to pass a resolution in support
        of our mission. Below you can find a sortable list of the Cities that
        have passed such resolutions, the date it was passed, and a copy of the
        signed resolution with the voting record from when the resolution was
        voted on in that city's public Council meeting. This list does not
        include Cities who have already passed the resolution but not yet
        provided us with a signed copy, or Cities who are at some stage of
        putting forth our resolution for action at a future Council meeting, of
        which there are many.
      </p>
      <p>
        If you are interested in passing a resolution like the cities below,
        please feel free to{" "}
        <a href="/documents/Resolution Template - v1.pdf">
          download this resolution template
        </a>{" "}
        which you can customize as needed.
      </p>
      <ResolutionsTable resolutions={resolutions} />
    </Layout>
  )
}
